<template>
  <div class="order">
    <header>
      <div class="header h5MaxWidth clear display alignCenter">
        <div
          class="back display alignCenter justifyCenter"
          onclick="window.history.go(-1)"
        >
          <i class="iconfont icon-arrow-left"></i>
        </div>
        <h2 class="flex1">Orders</h2>
        <div class="navskip" id="sideMenu"></div>
      </div>
    </header>
    <div class="cotent" v-for="item in dataList" :key="item.id">
      <div class="on">{{ item.startDate }}</div>
      <div class="to">
        <div class="toItem">₹ {{ shouyi(item) }}</div>
        <div class="toItem">₹ {{ shouyiAll(item) }}</div>
        <div class="toItem">₹ {{ item.price }}</div>
      </div>
      <div class="th">
        <div class="thItem">Daily Income</div>
        <div class="thItem">Estimated Income</div>
        <div class="thItem">Invest Price</div>
      </div>
      <div class="fo">
        <div class="one">
          <uni-image data-v-970e93ca="" style="width: 42px; height: 42px">
            <img
              :src="config.base + item.deviceImg"
              style="width: 42px; height: 42px"
              draggable="false"
          /></uni-image>
        </div>
        <div class="two">
          <div class="left">{{item.device}}</div>
          <div class="right"></div>
        </div>
        <div class="three">At Work</div>
      </div>
      <div class="last">
        <div>{{item.collectiondate}}</div>
        <div style="display: none">incom :{{item.incomecount}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/utils/config.js";
export default {
  data() {
    return {
      config,
      page: 1,
      uid: "",
      dataList: [],
    };
  },
  methods: {
    initPage() {
      this.getAjax(
        "api/device/index",
        {
          uid: this.uid,
          page: this.page,
        },
        "post"
      ).then((res) => {
        this.dataList = res.data;
      });
    },
    shouyi(item) {
      return item.profit_per > 0
        ? ((item.profit_per / 100) * item.price).toFixed(2)
        : item.profit;
    },
    shouyiAll(item) {
      return (
        (item.profit_per > 0
          ? ((item.profit_per / 100) * item.price).toFixed(3)
          : item.profit) * item.period
      ).toFixed(2);
    },
  },
  mounted() {
    this.uid = this.getItem("uid");
    this.initPage();
    this.timer = setInterval(() => {
      this.initPage();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.cotent {
  margin: 5px 10px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding-top: 45px;
  overflow: hidden;
  box-shadow: 0 0 5px #ddd;
}
.cotent .on {
  border-radius: 7px 0 0 7px;
  text-align: center;
  background-color: #dd052b;
  width: 135px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 10px;
}
.cotent .to {
  display: flex;
  font-size: 15px;
  color: #d8a418;
  font-weight: 700;
}
.cotent .to .toItem {
  flex: 1;
  text-align: center;
}
.cotent .th {
  display: flex;
  font-size: 12px;
  color: #000;
  margin-top: 5px;
}
.cotent .th .thItem {
  flex: 1;
  text-align: center;
}
.cotent .fo {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
}
.cotent .fo .one {
  border-radius: 7px;
  margin-left: 15px;
  font-size: 14px;
  margin-right: 12px;
}
.cotent .fo .two {
  margin-top: 2px;
  line-height: 22px;
  text-align: center;
  padding-right: 30px;
}
.cotent .fo .two .left {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #000;
}
.cotent .fo .two .right {
  font-size: 12px;
  font-family: PingFang SC;
  color: #17c39b;
}
.cotent .fo .three {
  width: 80px;
  height: 24px;
  font-size: 11px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  background-color: #dd052b;
  border-radius: 7px;
  margin-right: 24px;
}
.cotent .last {
  height: 25px;
  background-color: #dd052b;
  width: 100%;
  font-size: 12px;
  font-family: PingFang SC;
  color: #fff;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  box-sizing: border-box;
}
.footer {
  display: flex;
  position: fixed;
  bottom: 50px;
  width: 100%;
}
.footer .le {
  color: #fff;
  height: 40px;
  line-height: 40px;
  background-image: linear-gradient(90deg, #121e42, #184694);
  font-size: 17px;
  width: 70%;
  padding-left: 20px;
  box-sizing: border-box;
}
.footer .ri {
  width: 30%;
  text-align: center;
  height: 40px;
  color: #fff;
  font-size: 17px;
  line-height: 40px;
  background-color: #dd052b;
}
</style>
